import React from "react";

function Header() {
  return (
    <div className="center">
      <h1>Harshana Bandara</h1>
      <p>University of Peradeniya</p>
      <p>harshanabandaraofficial@gmail.com</p>
    </div>
  );
}

export default Header;
